import { Badge } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCalendar from "../../../widget/calendar/poppiCalendar"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import BookingsList from "./bookingsList"
import { getBookingsDate, getMonthData } from "./calendarStore"
import DialogOpenDay from "./dialogOpenDay"
import { useNavigate } from "react-router"

export const DashboardCalendar = (props) => {
    const [selectedDate, setSelectedDate] = useState(moment())
    const calendar = useSelector(state => state.calendar)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMonthData(selectedDate.month() + 1, selectedDate.year()))
        // eslint-disable-next-line
    }, [])
    return <>
        <LoaderDispatch
            dispatch={getMonthData(selectedDate.month() + 1, selectedDate.year())}
            refresh={`${selectedDate.month()}${selectedDate.year()}`}
        >
            <PoppiCalendar
                value={selectedDate}
                onSelect={
                    (value) => { setSelectedDate(value) }
                }
                onPanelChange={(value) => {
                    setSelectedDate(value)
                }}
                cellRender={(value) => {
                    var dataCalendar = calendar[value.format('DDMMYYYY')]
                    return <small>
                        <Badge status={(dataCalendar && dataCalendar.open) ? 'success' : 'error'} />
                        {dataCalendar?.notes && <PoppiIcon
                            icon='priority' />}
                        {dataCalendar?.reverse && <PoppiIcon
                            icon='reverse' />}<br />
                        {dataCalendar?.Bookings?.length > 0 && `${dataCalendar?.Bookings?.length} Dogs`}
                    </small>

                }} />
        </LoaderDispatch>
        <LoaderDispatch
            dispatch={getBookingsDate(selectedDate.format('DDMMYYYY'))}
            refresh={selectedDate}
            loadingText='Loading Bookings'
        >
            <DateDetails
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
        </LoaderDispatch>
    </>
}


const DateDetails = (props) => {
    const { selectedDate, setSelectedDate } = props
    const calendar = useSelector(state => state.calendar)
    const navigate = useNavigate()

    const [dialogOpenDay, setDialogOpenDay] = useState({ open: false, selectedDate: null })
    return <React.Fragment>
        {dialogOpenDay.open &&
            <DialogOpenDay
                dayData={calendar[selectedDate.format('DDMMYYYY')]}
                selectedDate={dialogOpenDay.selectedDate}
                open={dialogOpenDay.open}
                setSelectedDate={setSelectedDate}
                handleClose={() => { setDialogOpenDay({ open: false, selectedDate: null }) }} />}
        <PoppiCard
            title={`${selectedDate.format('ddd DD/MM/YYYY')} (${calendar.bookings.open ? `Open (${calendar.bookings.timings[0]} - ${calendar.bookings.timings[1]}) ${calendar.bookings.reverse ? '(Reverse)' : ''}` : 'Closed'})`}
            subtitle={`Notes: ${calendar[selectedDate.format('DDMMYYYY')]?.notes}`}
            actions={[
                <PoppiButton
                    fullWidth={true}
                    icon={<PoppiIcon icon='lockCog' />}
                    onClick={() => {
                        navigate(`/dashboard/openclose/${selectedDate.format('DDMMYYYY')}`, { state: { dayData: calendar[selectedDate.format('DDMMYYYY')] } })
                        // setDialogOpenDay(
                        //     { open: true, selectedDate: selectedDate }
                        // )
                    }}>
                    Open / Close Day
                </PoppiButton>
            ]}>

            <BookingsList bookings={[...calendar.bookings.Bookings]} />
        </PoppiCard>
    </React.Fragment>

}

export default DashboardCalendar 