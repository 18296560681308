import { Avatar, Group, Text } from "@mantine/core";
import React from "react";

export const PoppiListItem = (props) => {

    return <>
        <Group justify="space-between">
            <Group>
                {props.avatar &&
                    props.avatar}
                <div>
                    <Text fw={500}  >
                        {props.title}
                    </Text>
                    <Text c="dimmed" >
                        {props.description}
                    </Text>
                </div>
            </Group>
            {props.extra}
        </Group >
        {props.children}
    </>
}

PoppiListItem.defaultProps = {
    title: '',
    description: null,
    avatar: null,
    extra: null
}

export default PoppiListItem