import { Group, NavLink } from '@mantine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { logout } from '../auth/authStore';
import PoppiButton from '../widget/buttons/poppiButton';
import PoppiIcon from '../widget/icons/poppiIcons';

const NavbarHomepage = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authData = useSelector(state => state.auth)
    var itemsMenu = [
        {
            label: 'Home',
            key: 'home',
            icon: <PoppiIcon icon='home' />,
        },
        {
            label: 'Grooming',
            key: 'grooming',
            icon: <PoppiIcon icon='grooming' />,
        },
        {
            label: 'Spa',
            key: 'spa',
            icon: <PoppiIcon icon='spa' />,
        },
        {
            label: 'Tailoring',
            key: 'tailoring',
            icon: <PoppiIcon icon='pets' />,
        },
        {
            label: 'Events',
            key: 'events',
            icon: <PoppiIcon icon='event' />,
        },
        {
            label: 'Contacts',
            key: 'contacts',
            icon: <PoppiIcon icon='location' />,
        }
    ]

    var itemMenuAuthenticathed = [{
        label: 'Profile',
        key: 'profile',
        icon: <PoppiIcon icon='profile' />,
    }]

    var itemMenuAdmin = [{
        label: 'Dashboard',
        key: 'dashboard',
        icon: <PoppiIcon icon='dashboard' />,
    },
    {
        label: 'Dogs List',
        key: 'dashboard/dogs',
        icon: <PoppiIcon icon='pets' />,
    },
    {
        label: 'Users List',
        key: 'dashboard/users',
        icon: <PoppiIcon icon='users' />,
    },
    {
        label: 'Calendar',
        key: 'dashboard/calendar',
        icon: <PoppiIcon icon='calendar' />,
    },
    {
        label: 'Prices',
        key: 'dashboard/prices',
        icon: <PoppiIcon icon='prices' />,
    },
    {
        label: 'Custom Booking',
        key: 'dashboard/bookings/custom',
        icon: <PoppiIcon icon='booking' />,
    },

    ]

    return <>
        {itemsMenu.map((item) => {
            return <NavLink
                key={uuid()}
                label={item.label}
                leftSection={item.icon}
                onClick={() => {
                    if (item.key === 'home') {
                        navigate(`/`)

                    } else {
                        navigate(`/${item.key}`)

                    }

                    props.toggleNavbar()

                }}
            />
        })}
        {/* Profile Menu */}
        {authData?.authenticated &&
            <>
                <NavLink label='Account' disabled={true} />


                {itemMenuAuthenticathed.map((item) => {
                    return <NavLink
                        key={uuid()}
                        label={item.label}
                        leftSection={item.icon}
                        onClick={() => {
                            navigate(`/${item.key}`)

                            props.toggleNavbar()

                        }}
                    />
                })
                }
            </>
        }
        {/* Admin Menu */}
        {
            (authData?.authenticated && (authData.email === 'francesco.pocaforza@gmail.com' || authData.email === 'angeladipa@hotmail.it')) &&
            <>
                <NavLink label='Admin' disabled={true} />

                {
                    itemMenuAdmin.map((item) => {
                        return <NavLink
                            key={uuid()}
                            label={item.label}
                            leftSection={item.icon}
                            onClick={() => {
                                navigate(`/${item.key}`)

                                props.toggleNavbar()

                            }}
                        />
                    })
                }
            </>
        }
        {authData?.authenticated ?
            <Group justify="center" grow pb="xl" px="md">
                <PoppiButton
                    onClick={() => {
                        dispatch(logout())
                        props.toggleNavbar()
                    }}
                    icon={<PoppiIcon icon='logout' />}>Logout</PoppiButton>
            </Group>
            :
            <Group justify="center" grow pb="xl" px="md">
                <PoppiButton
                    onClick={() => {
                        navigate('login')
                        props.toggleNavbar()
                    }}
                    icon={<PoppiIcon icon='login' />}>Log in</PoppiButton>
                <PoppiButton
                    onClick={() => {
                        navigate('signup')
                        props.toggleNavbar()
                    }}
                    variant="default">Sign up</PoppiButton>
            </Group>
        }

    </>

}

export default NavbarHomepage