import { Avatar, CloseButton, Indicator, Input } from "@mantine/core"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import ImageLoader from "../../../widget/images/imageLoader"
import PoppiList from "../../../widget/list/PoppiList"
import PoppiListItem from "../../../widget/list/PoppiListItem"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllDogs } from "../dashboardStore"

export const DashboardDogs = (props) => {
    const dashboardData = useSelector(state => state.dashboard)

    return <LoaderDispatch
        dispatch={getAllDogs()}
    >
        <ListDogs
            listDogs={dashboardData.dogs}
        />
    </LoaderDispatch>
}

const ListDogs = (props) => {
    var listDogs = props.listDogs
    const [dogFilter, setDogFilter] = useState('')

    return <React.Fragment>
        <PoppiList
            header={<Input
                placeholder="Search..."
                style={{ width: '100%' }}
                value={dogFilter}
                onChange={(event => {
                    setDogFilter(event.currentTarget.value)
                })}
                rightSection={
                    <CloseButton
                        aria-label="Clear input"
                        onClick={() => setDogFilter('')}
                    />}
            />}
            dataSource={listDogs.filter((item) => {
                if (dogFilter) {
                    return item.name.toUpperCase().includes(dogFilter.toUpperCase())
                }
                return true
            }).sort((a, b) => {
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1
                } else {
                    return -1
                }
            })}
            renderItem={dogData => {
                return <DogDataDetails dogData={dogData} key={dogData.id} />
            }} />

    </React.Fragment>
}
export const DogDataDetails = (props) => {
    const { dogData } = props
    const navigate = useNavigate()
    return <PoppiListItem
        avatar={
            dogData.approved ?
                <Avatar ><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                :
                <Indicator label={'New'}>
                    <Avatar><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                </Indicator>
        }
        title={dogData.name}
        description={dogData.breed}
        extra={<PoppiButton
            iconOnly={true}
            icon={<PoppiIcon icon='details' />}
            onClick={() => { navigate(`/dashboard/dogs/${dogData.id}`) }} >Details</PoppiButton>}
    />
}

export default DashboardDogs 