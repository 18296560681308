import React, { useEffect, useState } from "react"
import ApiRequest from "../../api/api"
import { Image, Modal } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { PoppiIndexedDB } from "../PoppiIndexedDB/PoppiIndexedDB"

export const ImageLoader = (props) => {
    const { imageId } = props
    const [image, setImage] = useState(null)
    useEffect(() => {
        async function RetrieveImage() {
            if (imageId) {
                // Check if In LocalStorage
                var imageLocalStorage = await PoppiIndexedDB.Images.get(imageId)
                if (imageLocalStorage) {
                    console.log('GET IMAGE FROM STORAGE')
                    setImage(imageLocalStorage.data)
                } else {
                    console.log('GET IMAGE FROM API')
                    ApiRequest(`/images/${imageId}/get`, 'GET').then(
                        async (response) => {
                            console.log('Save Image to Indexed Db')
                            try {
                                await PoppiIndexedDB.Images.add(
                                    { id: imageId, data: response }
                                )
                            } catch (e) {

                            }
                            setImage(response)
                        },
                        (error) => {
                            setImage('error')
                        }
                    )
                }
            }
        }
        RetrieveImage()
    }, [imageId])
    var maxHeight = '10em'
    switch (props.size) {
        case 'xs':
            maxHeight = '2.5em'
            break;
        case 's':
            maxHeight = '5em'
            break;
        case 'm':
            maxHeight = '10em'
            break;
        case 'l':
            maxHeight = '20em'
            break;
        case 'xl':
            maxHeight = '40em'
            break;
        default:
            if (props.size) {
                maxHeight = props.size

            } else {
                maxHeight = '5em'

            }
            break;
    }

    const [opened, { open, close }] = useDisclosure(false);

    if (image) {
        return <div align='center'>
            <Modal opened={opened} onClose={close}>
                <Image src={image}
                    style={{
                        maxHeight: '80vh',
                        maxWidth: '80vw'
                    }} alt="description of content"
                />
            </Modal>
            <Image src={image}
                style={{
                    maxHeight: maxHeight,
                    maxWidth: maxHeight
                }} alt="description of content"
                onClick={open} />
        </div>

    }
    return <React.Fragment>
        Loading
    </React.Fragment>
}
ImageLoader.defaultProps = {
    imageId: null,
    size: 's'
}
export default ImageLoader