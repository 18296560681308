import { Checkbox, Group, Stack, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import React, { useState } from "react";
import PoppiCard from "../card/poppiCard";


export const PoppiSelectableList = (props) => {
    // Items: [
    // {value: abc,
    // label: abc,
    // description: abc,
    // icon: abc
    // ]
    return <PoppiCard
        title={props.title}
    >
        <Stack gap={1} >
            {props.items?.map((item, index) => {
                return <SelectableListItem
                    selected={
                        item.selected
                    }
                    item={item}
                    {...props} />
            })
            }
        </Stack >
    </PoppiCard >

}

const SelectableListItem = (props) => {
    const theme = useMantineTheme()

    const { item } = props
    const [hover, setHover] = useState(false)
    var style = { ...props.style }
    if (props.selected) {
        style.background = theme.colors.blue[2]
        style.borderRadius = 5
        style.border = '1px solid'
    } else {
        style.borderRadius = 5
        style.border = '1px solid'
        style.borderColor = theme.colors.blue[0]
        delete style.background
    }

    return <>
        <UnstyledButton
            p={10}
            style={style}
            onMouseOver={() => { setHover(true) }}
            onMouseOut={() => { setHover(false) }}
            onClick={() => {
                props.onChange(item.value)
            }}>
            <Group justify="space-between">
                <Group>
                    {item.icon}
                    <div>
                        <Text fw={500}  >
                            {item.label}
                        </Text>
                        <Text c="dimmed" >
                            {item.description}
                        </Text>
                    </div>
                </Group>
                <Checkbox
                    checked={props.selected}
                    tabIndex={-1}
                    styles={{ input: { cursor: 'pointer' } }}
                />
            </Group >

        </UnstyledButton>
    </>
}

PoppiSelectableList.defaultProps = {
    title: '',
    items: [],
    multiple: false,
    value: null,
    onChange: () => { },
    backgroundHover: '#A9D2F0',
    backgroundSelected: '#1890ff',
    subtitle: '',
    icon: null,
    actions: [],
    cover: null,
    extra: null,
    style: {}
}

export default PoppiSelectableList