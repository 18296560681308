import { Box, Card, Divider, Group, Stack, Text } from "@mantine/core";
import React from "react";
import PoppiEmpty from "../empty/poppiEmpty";
import PoppiCard from "../card/poppiCard";
import { v4 as uuid } from 'uuid';
import { randomId } from "@mantine/hooks";


export const PoppiList = (props) => {
    return <PoppiCard
        extra={props.extra}
        title={props.title}
        subtitle={props.subtitle}
        header={props.header}>
        {(!props.dataSource || props.dataSource?.length === 0) ?
            <PoppiEmpty />
            : <Stack gap={0} ml={5} mr={5} >
                {[...props.dataSource]?.map((item, index) => {
                    return <Box key={`${props.id}_${index}`}>
                        {index === 0 && <Divider m='xs' />}
                        {props.renderItem(item)}
                        <Divider m='xs' />
                    </Box>
                })}
            </Stack >
        }
    </PoppiCard>
}

PoppiList.defaultProps = {
    header: null,
    title: '',
    dataSource: [],
    renderItem: () => { },
    bordered: true,
    extra: null,
    id: randomId()
}

export default PoppiList