import React from "react"
import { useSelector } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiSelectableList from "../../../widget/form/poppiSelectableList"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { currency } from "../../../widget/text/formatText"
import { getUserPrices } from "../../profile/profileStore"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { Center } from "@mantine/core"

export const SpaSelection = (props) => {
    const { bookingData, setBookingData, activeStep, setActiveStep, calculateTotalPrice } = props

    return <PoppiCard
        title='Add a Spa service'
        subtitle='Want to add an extra treatment to your dog?'
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='arrowBack' />}
                fullWidth={true}
                onClick={() => {
                    setBookingData({
                        ...bookingData,
                        spa: [],
                        total: calculateTotalPrice({
                            ...bookingData,
                            spa: []
                        })
                    })
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='arrowForward' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    setActiveStep(activeStep + 1)
                }}
            >Next</PoppiButton>
        ]} >
        <LoaderDispatch
            dispatch={getUserPrices()}
        >
            <SpaListSelector {...props} />
        </LoaderDispatch>

    </PoppiCard>
}

const SpaListSelector = (props) => {
    const profileData = useSelector(state => state.profile)
    const { bookingData, setBookingData, calculateTotalPrice } = props


    var priceData = null
    if (bookingData.dogData.PriceId) {
        // Search by PriceId
        priceData = profileData.prices.find((item) => (item.id === bookingData.dogData.PriceId))
    }
    if (!priceData) {
        // Search by breed name
        priceData = profileData.prices.find((item) => (item.breed === bookingData.dogData.breed))

    }
    console.log(bookingData)
    if (bookingData.services.find((item) => (item.value === 'UltrasoundTeeth'))) {
        // Ultrasound Teeth Cleaning no Spa Services
        return <Center m='xs'>For the selected service there are no SPA treatment available</Center>
    }

    return <PoppiSelectableList
        onChange={(valueSelected) => {
            if (bookingData.spa.find((spa) => (spa.value === valueSelected.value))) {
                setBookingData(
                    {
                        ...bookingData,
                        spa: bookingData.spa.filter((spa) => (spa.value !== valueSelected.value)),
                        total: calculateTotalPrice({
                            ...bookingData,
                            spa: bookingData.spa.filter((spa) => (spa.value !== valueSelected.value)),
                        })
                    }
                )
            } else {
                setBookingData(
                    {
                        ...bookingData,
                        spa: [...bookingData.spa, valueSelected],
                        total: calculateTotalPrice({
                            ...bookingData,
                            spa: [...bookingData.spa, valueSelected]
                        })
                    }
                )
            }

        }
        }
        items={
            priceData.spa
                .filter((service) => {
                    if (bookingData.services.find((item) => (item.value === 'UltrasoundTeeth'))) {
                        // Ultrasound Teeth Cleaning no Spa Services
                        return false
                    }
                    if (bookingData.services.find((item) => (item.value === 'NailClipping'))) {
                        // Ultrasound Teeth Cleaning no Spa Services
                        if (service.value === 'UltrasoundTeeth') {
                            return service.enabled
                        }
                        return false
                    }
                    return service.enabled
                })
                .map((service) => {
                    return {
                        selected: !!(bookingData?.spa?.find((item) => (item.value === service.value))),
                        value: service,
                        label: service.label,
                        description: `${currency(service.price)} (${service.time} min)`,
                    }

                })
        }
    />
}
export default SpaSelection